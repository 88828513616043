import httpService from "@/request"

// 查询所有的社区介绍信息
export function getCommunityList(params) {
	return httpService({
		url: `/user/communityIntroduction/list`,
		method: 'get',
		params: params,
	})
}

// 添加社区介绍信息
export function AddCommunityIntro(params) {
	return httpService({
		url: `/user/communityIntroduction/insert`,
		method: 'post',
		data: params,
	})
}

// 根据社区介绍主键Id查询社区介绍信息
export function findCommunityIntro(params) {
	return httpService({
		url: `/user/communityIntroduction/findById`,
		method: 'get',
		params: params,
	})
}

// 修改社区介绍信息
export function updateCommunityIntro(params) {
	return httpService({
		url: `/user/communityIntroduction/update`,
		method: 'post',
		data: params,
	})
}

// 删除社区介绍信息
export function delCommunityIntro(params) {
	return httpService({
		url: `/user/communityIntroduction/delete`,
		method: 'post',
		data: params,
	})
}

// 启用社区介绍信息
export function enableCommunityIntro(params) {
	return httpService({
		url: `/user/communityIntroduction/enable`,
		method: 'get',
		params: params,
	})
}