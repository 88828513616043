<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <span>社区介绍</span>
            </a-space>
        </div>
        <div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
                    <a-input v-model="form.name" style="width: 200px" placeholder="模版名称"></a-input>
                    <a-select style="width: 200px" v-model="form.status" placeholder="启用状态">
                        <a-select-option :value="1">启用中</a-select-option>
                        <a-select-option :value="2">未启用</a-select-option>
                    </a-select>
                    <a-input v-model="form.createName" style="width: 200px" placeholder="创建人"></a-input>
                    <a-range-picker @change="handlerTime" v-model="selTime" style="width: 300px" value-format="YYYY-MM-DD HH:mm:ss" :placeholder="['最近修改时间开始','最近修改时间结束']"></a-range-picker>
                    <a-range-picker @change="handlerTime2" v-model="selTime2" style="width: 240px" value-format="YYYY-MM-DD HH:mm:ss" :placeholder="['创建时间开始','创建时间结束']"></a-range-picker>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
            </a-row>
        </div>
        <a-button style="margin: 10px" class="add-btn" @click="detailShow = true;mode = 1">新增模版</a-button>
        <div class="main">
            <a-table :columns="columns" :data-source="tableData" 
                :pagination="pagination" @change="handlerChange" :scroll="{ x: 1700 }" 
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: selectionChoosed,
                }"
                :row-key="
                (record, index) => {
                return record.id;
                }">
                <span slot="content" slot-scope="text, record">
                    <a-popover>
                        <template slot="content">
                            <div style="width: 600px">{{record.content}}</div>
                        </template>
                        <div>{{doStr(record.content,21)}}</div>
                    </a-popover>
                </span>
                <template slot="status" slot-scope="text,record">
                    <a-switch checked-children="启用中"
                        un-checked-children="未启用"
                        :checked="record.status == 1"
                        @change="handleEnable(record)">
                    </a-switch>
                </template>
                <template slot="action" slot-scope="text,record">
                    <span><a @click=edit(record)>编辑</a></span>
                    <span><a style="margin-left: 8px;color:red" @click=del(record)>删除</a></span>
                </template>
            </a-table>
            <div class="action">
                <a-dropdown :disabled="!hasSelected">
                    <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="del"> 批量删除 </a-menu-item>
                    </a-menu>
                    <a-button> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                    {{ `已选择 ${selectedRowKeys.length} 条` }}
                    </template>
                </span>
            </div>
        </div>
        <a-drawer :title="mode==1?'新增模版':'编辑模版'" :width="720" :visible="detailShow" :body-style="{ paddingBottom: '60px' }" @close="detailClose">
            <div class="drawer-content">
                <span>基本信息</span>
                <a-divider></a-divider>
                <a-row>
                    <a-form-model :model="addForm" :rules="rules">
                    <a-col :span="24">
                        <a-form-model-item label="模版名称" prop="name">
                            <a-input style="width: 560px" placeholder="请输入模版名称" v-model="addForm.name"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="内容" prop="content">
                            <a-textarea style="width: 560px" :autosize="{minRows: 8}" placeholder="可输入不超过1024个字符的内容" v-model="addForm.content"></a-textarea>
                            <div>
                                已输入 {{addForm.content.length}} / 1024 字
                            </div>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item label="图片">
                            <commonUpload :fileList='fileList' @handleChange="handleChange" />
                        </a-form-model-item>
                    </a-col>
                    </a-form-model>
                </a-row>
            </div>
            <div class="drawer-footer" style="float: left">
                <a-button @click="addConfirm" type="primary" :style="{ marginRight: '8px' }">
                    确认
                </a-button>
                <a-button @click="detailClose"> 关闭 </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import { getCommunityList, AddCommunityIntro, enableCommunityIntro,
    findCommunityIntro, updateCommunityIntro, delCommunityIntro} from "@/api/operation/comIntro"
import {form,addForm, rules, columns,doStr } from "./depend/config";
export default {
    data() {
        return {
            form: form,
            selTime: [],
            selTime2: [],
            addForm: addForm,
            fileList: [],
            mode: 1, //1新增 2编辑
            activeId: undefined,
            rules: rules,
            columns: columns,
            doStr: doStr,
            tableData: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            //详情
            detailShow: false,
            //批量删除
            selectedRowKeys: [],
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let obj = Object.assign(this.form, {pageNum: this.pagination.current,size: this.pagination.pageSize})
            getCommunityList(obj).then(res => {
                this.tableData = res.data.rows;
                this.pagination.total = res.data.total
            })
        },
        reset() {
            this.form = {
                name: '',
                status: undefined,
                createName: '',
                nearModifyDateStart: '',
                nearModifyDateEnd: '',
                createDateStart: '',
                createDateEnd: '',
            },
            this.selTime = [],
            this.selTime2 = [],
            this.getData()
        },
        handlerChange() {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        detailClose() {
            this.addForm = {
                name: '',
                content: '',
                imgUrls: [],
            },
            this.fileList = []
            this.detailShow = false
        },
        //添加/编辑模版
        addConfirm() {
            if(this.mode == 1) {
                AddCommunityIntro(this.addForm).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } else {
                let obj = Object.assign(this.addForm, {id: this.activeId})
                updateCommunityIntro(obj).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.detailClose()
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        //编辑回显
        edit(val) {
            this.mode = 2;
            findCommunityIntro({communityIntroductionId: val.id}).then(res => {
                let data = res.data;
                this.addForm.name = data.name;
                this.addForm.content = data.content;
                if(data.imgList.length>0){
					const pic = []
					this.addForm.imgUrls.push(data.imgList[0].url)
					for(let item of data.imgList){
						let obj = {
							name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
							url: this.$ImgUrl(item.url),
							uid:item.url.split('_')[1],
							status:'done',
							thumbUrl: this.$ImgUrl(item.url),
						}
						pic.push(obj)
					}
					this.fileList = pic
				}
                this.activeId = data.id
            })
            this.detailShow = true;
        },
        //删除
        del(val) {
            this.$confirm({
                title: "是否删除该模版？",
                icon:'delete',
                onOk:async()=>{
                    let res = await delCommunityIntro({communityIntroductionIds: [val.id]})
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                },
            })
        },
        //批量删除
        selectionChoosed(val) {
            this.selectedRowKeys = val
        },
        handleMenuClick(data) {
            if (data.key === "del") {
                this.$confirm({
				title: "是否删除选中的模版？",
				icon:'delete',
				onOk:async()=>{
                        let res = await delCommunityIntro({communityIntroductionIds: this.selectedRowKeys})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.selectedRowKeys = [];
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
			}
        },
        //启用
        handleEnable(val) {
            enableCommunityIntro({communityIntroductionId: val.id}).then(res => {
                if(res.code === 200){
                    this.$message.success(res.msg);
                    this.getData()
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        //修改时间
        handlerTime(val) {
            this.form.nearModifyDateStart = val[0];
            this.form.nearModifyDateEnd = val[1];
        },
        handlerTime2(val) {
            this.form.createDateStart = val[0];
            this.form.createDateEnd = val[1];
        },
        //图片上传
        handleChange(data) {
			this.fileList = data;
			this.addForm.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.addForm.imgUrls.push(ele.response.data)
				}
			})
		},
    },
    computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
}
</script>

<style lang="less">
.drawer-content {
    padding: 16px;
}
</style>