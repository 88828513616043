export const form = {
	name: '',
    status: undefined,
    createName: '',
    nearModifyDateStart: '',
    nearModifyDateEnd: '',
    createDateStart: '',
    createDateEnd: '',
}

export const addForm = {
	name: '',
    content: '',
    imgUrls: [],
}

export const columns = [
	{
		title: "模版名称",
		width: "9%",
		dataIndex: "name",
	},
	{
		title: "内容",
		width: "22%",
		dataIndex: "content",
		scopedSlots: { customRender: "content" },
	},
	{
		title: "启用状态",
		width: "12%",
		dataIndex: "status",
		scopedSlots: { customRender: "status" },
	},
    {
		title: "创建人名称",
		width: "12%",
		dataIndex: "createName",
	},
	{
		title: "最近修改时间",
		width: "16%",
		dataIndex: "nearModifyDate",
	},
	{
		title: "创建时间",
		width: "16%",
		dataIndex: "createDate",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]

export const rules = {
	name: [{ required: true, message: "请输入名称", trigger: "blur" }],
	content: [{ required: true, message: "请输入内容", trigger: "blur" }],
}

export function doStr(str, number) {
	if(str.length >= number) {
		return str.substr(0,number-1) + '...' 
	} else {
		return str
	}
}